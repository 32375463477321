import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
        A website in development to help you learn Te Reo! Contact <a
          className="App-link"
          href="mailto:mail@learntereo.com"
          target="_blank"
          rel="noopener noreferrer">
        mail@learntereo.com
        </a> for more information or to register interest!
        </p>
      </header>
    </div>
  );
}

export default App;
